<template>
  <main class="thanks-page">
    <div class="container">
      <h1 class="thanks-page__title">СПАСИБО!</h1>
      <div class="thanks-page__wrapper">
        <span class="thanks-page__description"
          >Вы успешно приобрели курс и можете приступить к прохождению</span
        >
        <router-link :to="{ name: 'account_education' }" class="btn btn--lg btn--main"
          >Перейти к курсу</router-link
        >
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "ThanksPage",
  components: {},
};
</script>

<style lang="stylus">
.header{
}
.thanks-page{
  background-image url("/static/images/thank_bg.png")
  background-repeat no-repeat
  background-position right
  background-size contain
  padding: 0 30px
  padding-top: 110px

  +below(1620px){
    background-size 50% 100%
  }

  +below(1280px){
    background-image none
  }

  &__img{
    width 912px
    height 842px
    position absolute
    right: 70px;
  }

  & .container{
    padding: 160px 0 200px 0
    display flex
    flex-direction column
    gap 180px

    +below(1280px){
      gap 80px
    }
    +below(700px){
      gap 50px
      padding-bottom 60px
    }
  }

  &__wrapper{
    display flex
    flex-direction column
    gap 60px
    max-width 760px
    align-items flex-start

    +below(700px){
      gap 50px
    }

    & .btn{
      text-transform none
      font-weight: 600;
      font-size: 1.125em

      +below(700px){
        width 100%
      }
    }
  }

  &__title{
    color var(--pink)

    +below(700px){
      font-size 44px
    }
  }

  &__description{
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    color var(--dark)

    +below(700px){
      font-size 24px
    }
  }
}
</style>
